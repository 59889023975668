.cell-bold {
    font-weight: bold;
}

.cell-underline {
    text-decoration: underline;
}

/*.ht_master tr:nth-child(1) > td:nth-child(2) {*/
/*    font-weight: bold;*/
/*}*/
/*.ht_master tr:nth-child(7) > td:nth-child(2) {*/
/*    font-weight: bold;*/
/*}*/
/*.ht_master tr:nth-child(12) > td:nth-child(2) {*/
/*    font-weight: bold;*/
/*}*/
/*.ht_master tr:nth-child(16) > td:nth-child(2) {*/
/*    font-weight: bold;*/
/*}*/
/*.ht_master tr:nth-child(23) > td:nth-child(2) {*/
/*    font-weight: bold;*/
/*}*/



.engineering-table {

    margin-left: auto;
    width: 85%;
    margin-right: auto;
    max-height: 1350px;
}

.totalen_table {
    height: 500px;
}

.htDimmed {
    background-color: #f8f8f8 !important;
}

.htCore tbody tr td:first-of-type {
    background-color: #fefefe !important;
    color: #000 !important;
}

.tablefix {
    width: calc(77% + 46px);
    margin: 25px 0px 25px 90px;
    position: relative;
}

.flexy {
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.handsontable {
    display: flex !important;
}

.MuiCheckbox-colorPrimary.Mui-checked {
    color: #292073 !important;
}