.custom-table-style {
    font-size: 0.8em;
}

.f_blad_table td:nth-child(n+3):nth-child(-n+9), .f_blad_table th:nth-child(n+3):nth-child(-n+9) {
    border-collapse: collapse;
    border-right: 1px solid #0f0f0f;
    text-align: center;
    padding: 0.75rem 0.25rem;
}

.f_blad_table td:nth-child(3), .f_blad_table th:nth-child(3) {
    border-collapse: collapse;
    border-left: 1px solid #0f0f0f;
}

.hotTable {
    /*float: right;*/
    /*overflow: scroll;*/
    /*-webkit-overflow-scrolling: touch;*/
}

.bg-dark {
    background-color: #444444 !important;
}

.mt-5-top,
.my-5-top {
    margin-top: 1.5rem !important;
}

.ht_master tr > td:nth-child(n+10):nth-child(-n+13) {
    background-color: #e1e1e1;
}

.ht_master tr > td:nth-child(n+14):nth-child(-n+17) {
    background-color: #292073;
}

.template tbody td {
    background-color: #f39c12;
}

/*!*Specific column (B)*/


.ht_master tr > td:nth-child(0) {
    font-weight: bold;
}

.cell-bold {
    font-weight: bold;
}

.cell-underline {
    text-decoration: underline;
}

.cell-red {
    background-color: #e74c3c !important;
}


.table-color-reset {
    background-color: white !important;
}

.table-color-reset-template tbody td {
    background-color: #f39c12 !important;
}

.table-height {
    max-height: 935px !important;
}

.io-table {
    display: inline;
    padding-left: 25.5%;
    vertical-align: middle;
}

.io-table thead {
    font-weight: bold;
}

.io-table thead th {
    border-top: none;
}

.io-table th, td {
    padding: 0.25rem 0.25rem !important;
}

.padfix td {
    padding: 3px 0px 0px 0px !important;
    color: white;
    background-color: #292073;
}

/*remove bug which shows invalid input after trimming rows*/
.handsontable td.htInvalid {
    background-color: rgba(0, 0, 0, 0) !important;
}

.cell-bg-lightgray {
    background-color: #777;
}

.cell-bg-darkgray {
    background-color: #e1e1e1;
}